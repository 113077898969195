@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.cdnfonts.com/css/hero-new');

/*@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../assets/fonts/Montserrat-Regular.ttf) format('truetype');
  !* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' *!
}*/

$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
/*$icon-bg-color: rgba(255, 255, 255, 0.05);*/
$icon-bg-color: rgba(255, 255, 255, 0.00);
$icon-size: 35px;
$ka-green: rgba(#0eff28, 1);
$ka-green-dark: rgba(#0bcc20, 1);
$ka-blue-dark: rgba(#3585ca, 1);
$dark-green: rgba(#161b27, 1);
$dark-black: rgba(#0b0e14, 1);
$ka-bg-box: rgba(#000000, 0.5);
$discord-blue: #7289da;

@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
  margin: 0;
  height: 100vh;
  color: #353535;
  //font-family: 'Montserrat', sans-serif;
  font-family: 'Hero New', sans-serif;
}

body {
  h1, h2, h3, h4, h5, h6, p, a, span, label, td, th, input, button {
    font-family: 'Hero New', sans-serif;
  }
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
  background-color: $dark-black;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .main {
    width: 100%;
    background-color: #f5f6f6;
    display: flex;
    flex-direction: column;

    > div:last-child {
      padding: 30px;
    }

    .main-content {
      overflow: scroll;
    }

    .main-header {
      min-height: 40px;
      //height: 40px;
      background-color: white;
      padding: 30px;
      display: flex;
      justify-content: space-between;

      .breadcrumbs {
        padding-top: 10px;
      }
    }

    .action-panel {
      margin-bottom: 30px;
      display: flex;
      gap: 15px;
    }

    .search-panel {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 15px;
    }

    .basic-form, .titled-form {
      background-color: white;
      padding: 20px;

      .checkbox-inline {
        flex-direction: row;
      }

      .form-stack-horizontal {
        margin-top: 20px;
        .MuiFormControl-root {
          margin-top: 0 !important;
        }
      }

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiFormControl-root:not(:first-child) {
        margin-top: 20px;
      }

      input {
        //border: 1px solid #a1a1a1;
        border-radius: 10px;
        min-height: 30px;
        //padding-left: 10px;
      }

      .autoComplete input {
        border: 0;
      }

      .autoComplete fieldset {
        border: 1px solid #a1a1a1;
        border-radius: 10px;
      }

      .autoComplete > div > div {
        padding: 0;
      }

      .textinput {
        padding-right: 10px;
      }

      input:not([type="submit"]), textarea {
        width: 98%;
      }

      .file-upload {
        border: 1px solid #a1a1a1;
        border-radius: 10px;
      }

      .datetime-picker {
        width: 100%;
      }

      .datetime-picker fieldset {
        border: 0;
      }

      .datetime-picker input {
        padding-top: 1px;
        padding-bottom: 1px;
        padding-right: 10px;
      }

      .form-component-label {
        text-align: left;
        font-size: 12px;
        padding-top: 9px;
      }

      @media (min-width: 1200px) {
        .form-component-label {
          text-align: right;
        }
      }

      .dropzone {
        cursor: pointer;
      }

      .selected-file {
        padding: 5px;
      }

      .selected-file img {
        width: 100%;
      }
    }

    .titled-form {
      h4 {
        text-transform: uppercase;
        margin-bottom: 30px;
      }
    }
  }

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-logo {
    text-align: center;
    margin-top: 20px;

    img {
      height: 65px;
    }
  }

  .collapse-controller {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .pro-sidebar {
    color: white;
  }

  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    background-color: #25474b;
    border-bottom: 0;
    min-height: 100px;
    height: 100px;
  }

  .pro-sidebar-content, .pro-sidebar-footer {
    background-color: #2A383C;
  }

  .pro-item-content, .pro-item-content a {
    color: white;
  }

  .sidebar-language {
    position: relative;
    top: -30px;
    padding: 10px;
    text-align: right;
    cursor: pointer;

    > * {
      width: 20px;
    }
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      border: 0;
      cursor: pointer;

      span {
        margin-left: 5px;
        font-size: 13px;
      }

      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  .social-bagdes {
    margin-top: 10px;

    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;

    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }

    .block {
      direction: rtl;

      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  .ka-accordion {
    margin-top: 30px;
  }

  .ka-list {

    margin-top: 30px;

    li:nth-child(odd) {
      background-color: #eeeeee;
    }

  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.white {
  color: white;
}

.green {
  color: $ka-green;
}

.bold {
  font-weight: bold !important;
}

.cursive {
  font-style: italic;
}
.normal {
  font-style: normal;
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.ka-title {
  color: white;
  text-transform: uppercase;
  text-align: center;

  span {
    color: $ka-green;
    font-weight: bold;
  }
}

h2.ka-title {
  line-height: 40px;
  font-size: 30px;
  font-weight: 300;
}

@media(max-width: 768px) {
  h2.ka-title {
    font-size: 22px;
  }
}

div.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ka-box {
  background-color: $ka-bg-box;
  height: 100%;
  padding: 20px;
  overflow: hidden;
  border-radius: 15px;
}
.ka-box.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

nav.ka-menu {

  padding-top: 0;

  .navbar-brand {
    padding-top: 0;
    .brand-wrapper {
      background-color:  $ka-bg-box;
      border-radius: 0 0 15px 15px;
      padding: 16px 20px 30px 20px;
    }
  }

  a {
    font-size: 15px;
    color: white;
    text-transform: uppercase;
    font-weight: bolder;

    &[aria-expanded="true"] {
      color: $ka-green;
    }

    &:hover {
      color: $ka-green;
    }
  }

  .separator {
    color: $ka-green;
    font-weight: bold;
    padding-inline: 10px;
  }

  .navbar-toggler {
    border: 2px solid $ka-green;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
  }

  .dropdown {
    & > a {
      &:after {
        display: none;
      }
    }
    .dropdown-menu {
      background: black;
      border-radius: 10px;

      .dropdown-item {
        &:hover {
          background: transparent;
        }
      }
    }
  }

  @media(max-width: 991px) {
    .navbar-collapse {

      margin-top: 10px;
      border-top: 2px solid $ka-green;
      background-color: rgba($dark-green, 1);
      padding: 15px;

      .nav-item {
        margin-top: 15px;
      }

      .separator {
        display: none;
      }
    }
  }

}

header {
  background-color: #171b27;
  background-image: url("../../src/assets/img/WEB_2_Background.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 50px;

  .title {
    padding-top: 100px;
    padding-inline: 15%;
    margin-bottom: 100px;

    h1 {
      font-weight: bold;
      font-size: 5em;
    }

    @media(max-width: 768px) {
      h1 {
        font-size: 2.3em;
      }
    }
  }

  @media(max-width: 768px) {
    .title {
      padding-top: 50px;
      margin-bottom: 40px;
    }
  }

  .title.small {
    padding-top: 30px;
    margin-bottom: 30px;
  }

  @media(max-width: 768px) {
    .title {
      padding-inline: 0;
    }
  }
}

section {
  padding-block: 50px;
}

.choose-membership {
  border: 0;
  background-color: $ka-green;
  color: black;
  border-radius: 10px;
  padding: 20px 30px;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  transition: .3s all;

  &:hover {
    background-color: $ka-green-dark;
  }
}

@media(max-width: 768px) {
  .choose-membership {
    margin-top: 20px;
    font-size: 12px;
  }
}

.team {

  padding-top: 100px;

  .profile {

    padding-inline: 15px;

    > div {

      //height: 100%;
      border-radius: 10px;
      background-color: $dark-black;
      padding: 20px 30px;

      .profile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .profile-img {
          img {
            width: 110px;
          }
        }

        .profile-nickname {
          color: $ka-green;
          text-transform: uppercase;
          font-size: 22px;
          font-weight: bold;
        }

        .profile-fullname {
          color: white;
          font-size: 18px;
          font-style: italic;
        }
      }

      .profile-body {
        margin-top: 20px;
        color: white;
      }

      @media(max-width: 991px) {
        & {
          margin-top: 20px;
        }
      }
    }
  }
}

.bg-dark-black {
  background-color: $dark-black;
}

.bg-dark-green {
  background-color: $dark-green;
}

.partners {
  padding: 100px;

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media(max-width: 768px) {

    & {
      padding-inline: 0;
    }

    .row {
      row-gap: 30px;
      flex-direction: column;

      > div {
        width: 100%;
      }

      ul {
        padding-left: 0;
      }
    }

    img {
      height: 30px;
    }
  }
}

.reviews-list {
  margin-bottom: 20px;
  .slick-slide {
    transform: translateX(0);
  }
  .reviews-list-item {
    p {
      color: white;
      font-size: 15px;
      font-weight: 300;
    }
    h5 {
      color: $ka-green;
      font-size: 18px;
    }
  }
}

footer {
  color: white;
  padding: 40px;

  .row {
    .col:first-child {
      text-align: center;

      p {
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 300;

        a {
          color: white;
          text-transform: uppercase;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      img {
        height: 130px;
        margin-bottom: 20px;
      }
    }

    .col:last-child {
      padding-left: 50px;
    }
  }

  .footer-disclaimer {
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
  }

  .footer-address {
    .footer-address-wrapper {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 300;
      font-style: italic;
    }
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

}

.ka-box .membership-item {
  padding-block: 30px;
}

.free-courses {
  margin-top: 100px;
}

.membership-item {
  text-align: center;
  color: white;

  h4 {
    font-size: 28px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    padding-top: 15px;
  }

  li {
    font-weight: bold;
    margin-top: 15px;
    font-size: 18px;
  }

  .membership-price {

    p {
      font-weight: bold;
      font-size: 20px;
    }

    .blue {
      color: #42a6fc;
    }
    .green {
      color: $ka-green;
    }
    .euro {
      font-size: 16px;
    }

  }

  button {
    border-radius: 10px;
    color: black;
    border: 0;
    padding: 15px 30px;
    width: 250px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    transition: .3s all;
  }
  button.blue {
    background-color: #42a6fc;
    &:hover {
      background-color: #3585ca;
    }
  }
  button.green {
    background-color: $ka-green;
    &:hover {
      background-color: $ka-green-dark;
    }
  }
}

button.ready {
  transition: .3s all;
  border: 0;
  background-color: $ka-green;
  &:hover {
    background-color: $ka-green-dark;
  }
}

@media(max-width: 768px) {
  .membership-item {
    margin-block: 20px;
  }
}

.ready {
  button {
    margin-top: 40px;
    background-color: $ka-green;
    color: black;
    border-radius: 10px;
    padding: 15px 25px;
    font-size: 20px;

    span {
      font-weight: bold;
    }
  }
}

#hp-membership-info {

  h2 {
    font-size: 20px;
    text-align: left;
    font-weight: bold;
    line-height: 30px;
  }

  .ka-box {
    padding-inline: 50px;
    padding-top: 40px;

    @media(max-width: 768px) {
      padding-inline: 20px;
    }

    p {
      line-height: 22px;
      font-size: 15px;
      font-weight: 300;
    }
  }

  & > div:nth-child(2) .ka-box {
    //background-image: url("../../src/assets/img/triko.png");
    background-repeat: no-repeat;
    background-position: top +30px right;
    background-size: 200px;
    padding-inline: 0;
    //padding-top: 300px;

    .ka-box-text {
      padding-inline: 40px;
      margin-top: 20px;

      @media(max-width: 768px) {
        & {
          padding-inline: 20px;
        }
      }
    }
  }

  @media(max-width: 991px) {
    & > div:nth-child(2) .ka-box {
     background-image: none;
      padding-top: 20px;
      margin-top: 20px;
    }
    & > div:nth-child(3) .ka-box {
      margin-top: 40px;
    }
  }

}

.hp-membership {
  margin-top: 50px;
  gap: 50px;

  @media (max-width: 500px) {
    & {
      gap: 30px;
    }
  }

  & > div {
    text-align: center;

    @media (max-width: 768px) {
      & {
        flex: 1;
      }
    }
  }
  h4 {
    color: white;
    margin-top: 10px;
    text-transform: uppercase;
    text-align: center;
  }
  img {
    width: 100%;
  }
}

@media(max-width: 991px) {
  .hp-memberships-section {
    > div:last-child .ka-box {
      margin-top: 20px;
    }
  }
}

.row.product-grid {

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  grid-row-gap: 60px;
  text-align: center;
  color: white;

  .product-image {
    img {
      width: 100%;
    }
  }

  .product-name {
     margin-top: 15px;
    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .product-price {


  }

  .product-button {
    button {
      border-radius: 10px;
      border: 0;
      padding: 10px 20px;
      background-color: rgb(240, 240, 240);
      color: black;
      transition: .3s all;

      &:hover {
        background-color: $ka-green;
      }
    }
  }

  @media(max-width: 991px) {
    & {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 60px;
    }
  }

  @media(max-width: 768px) {
    & {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 60px;
    }
  }

}

.product-detail {

  margin-bottom: 20px;

  h1, h2, h3 {
    color: white;
  }

  img {
    width: 98%;
  }

  .MuiFormControl-root {
    margin-right: 10px;
  }

  .product-button {

    button {
      border-radius: 10px;
      border: 0;
      padding: 10px 20px;
      background-color: $ka-green;
      color: black;
      margin-top: 40px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .product-description {
    p {
      color: white;
    }
    li {
      color: white;
    }
  }

  .product-variants {

    margin-top: 30px;

    .MuiFormControl-root {
      //border: 1px solid white;
      max-width: 200px;

      label {
        color: white;
        background-color: $dark-green;
        padding-inline: 5px;
      }
      label.Mui-error {
        color: #d32f2f;

        +div fieldset {
          border-color: #d32f2f;
        }
      }

      svg {
        color: white;
      }

      .MuiInputBase-formControl {
        color: white;

        fieldset {
          border-color: white;
        }
      }

    }

  }

}

button.button-green, .button-green {
  background-color: $ka-green;
  border-radius: 10px;
  padding: 10px 20px;
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    //text-decoration: underline;
    color: black;
    background-color: $ka-green-dark;
  }
}

button.discord-button {
  background-color: $discord-blue;
  border-radius: 10px;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    background-color: $discord-blue;
  }

  &.Mui-disabled {
    background-color: $discord-blue;
  }
}

.text-right {
  text-align: right;
}

.cart {

  h4 {
    color: white;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .referal-field {
    margin-top: 20px;
  }

  button.button-green {
    margin-top: 40px;

    @media(max-width: 768px) {
      & {
        text-align: center;
      }
    }
  }

  .cart-empty {

    h3 {
      font-size: 22px;
      margin-bottom: 30px;
    }

  }

  .packeta-selector-open {
    width: 140px;
    background-color: #ba1b02;
    border: 0;
    color: white;
    margin-bottom: 20px;
    margin-right: 20px;
    img {
      width: 60px;
    }
  }

  table th {

    text-transform: uppercase;

    &:not(:last-child) {
      padding-right: 50px;
    }
  }

  .cart-item-mobile-row {

    .remove-item {
      color: red;
      font-weight: bold;
      text-decoration: none;
      border: 0;
      background-color: transparent;
      font-size: 26px;
    }

    img {
      width: 100%;
    }

    color: white;
    text-align: center;
  }

  .cart-item-row {

    td:not(:last-child) {
      padding-right: 50px;
    }

    .remove-item {
      color: red;
      font-weight: bold;
      text-decoration: none;
      border: 0;
      background-color: transparent;
      font-size: 26px;
    }

    .cart-item-img {
      img {
        width: 100px;
        margin-right: 30px;
        margin-left: 10px;
      }
    }

    @media(max-width: 991px) {
      td:not(:last-child) {
        padding-right: 0;
      }
    }

  }

  .gift-stack {
    gap: 30px;

    @media(max-width: 768px) {
      & {
        flex-direction: column;
      }
    }
  }

  .gift-item {
    text-align: center;
    max-width: 200px;

    img {
      width: 100%;
    }

    p {
      margin-top: 10px;
      color: white;
    }

    button {
      margin-top: 0;
      padding: 5px 15px;
      font-size: 14px;
    }
  }

}

.public-form {

  .button-secondary {
    border: 1px solid $ka-green;
    background: transparent;
    color: $ka-green;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
  }

  label {
    color: white;
    background-color: $dark-green;
    padding: 2px 5px;
  }

  .MuiInputBase-root {
    border: 1px solid white;
    margin-right: 20px;

    input {
      color: white !important;

      &::placeholder {
        color: white;
      }
    }
  }

}

.payment-form {
  color: white;

  .MuiSelect-select {
    color: white;
  }

  .radio-group {
    margin-top: 50px;
    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;

      .MuiRadio-root {
        input + span {
          color: $ka-green;
        }
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 30px;

    input {
      color: white;

      &.Mui-disabled {
        -webkit-text-fill-color: white !important;
      }
    }

    textarea {
      color: white;
    }

    .Mui-focused {
      fieldset {
        border-color: $ka-green !important;
      }
    }

    label {
      color: white;
    }

    label.Mui-focused {
      color: $ka-green;
    }

    .MuiInputBase-root {
      fieldset {
        border: none;
        border-bottom: 1px solid white;
      }

      &:hover {
        fieldset {
          border-color: $ka-green;
        }
      }
    }
  }

  .payment-summary-table {

    td, th {
      padding-right: 40px;
    }

  }

}

.discord-panel {
  margin-bottom: 40px;

  label {
    color: white;
  }
  .referal-stack {
    align-items: center;
    gap: 20px;
    padding-left: 10px;

  }
}

.referal-panel {
  margin-bottom: 40px;

  label {
    color: white;
  }
  .referal-stack {
    align-items: center;
    gap: 20px;
    padding-left: 10px;

    @media(max-width: 768px) {
      & {
        flex-direction: column;
      }
    }
  }
  input {
    color: white;
    -webkit-text-fill-color: white !important;
    border: 1px solid white;
    border-radius: 10px;
    width: 350px;
  }
}

#card-panel {

  .p-BillingAddressForm {
    display: none;
  }

}

.order-table {
  width: 100%;
  margin-top: 30px;
}

.user-order-table {
  table {
    width: 100%;
    color: white;

    & > tbody {
      & > tr:nth-child(even) {
        & > td {
          background-color: rgba(#777777, 0.3);
        }
      }

      a {
        cursor: pointer;
        margin-inline: 10px;
        padding: 5px 20px;
      }
    }

    thead {
      td {
        font-weight: bold;
        text-align: center;
        font-size: 18px;
      }
    }
    td {
      padding-block: 20px;
      text-align: center;
    }
  }
}

.user-order-table-mobile {
  table {
    width: 100%;
    color: white;

    & > tbody {
      & > tr.outer:nth-child(even) {
        & > td {
          background-color: rgba(#777777, 0.3);
        }
      }

      a {
        cursor: pointer;
        margin-inline: 10px;
        padding: 5px 20px;
      }
    }

    thead {
      td {
        font-weight: bold;
        text-align: center;
        font-size: 18px;
      }
    }
    td {
      padding-block: 20px;
      text-align: center;
    }
  }
}

.profile-order-detail {
  .buttons-row {
    margin-bottom: 20px;

    button {
      margin-right: 20px;
    }
  }
}
.user-order-detail-table {
  table {
    width: 100%;
    color: white;

    tbody {
      tr:nth-child(even) {
        td {
          background-color: rgba(#777777, 0.3);
        }
      }

      a {
        cursor: pointer;
        margin-inline: 10px;
        padding: 5px 20px;
      }
    }

    thead {
      td {
        font-weight: bold;
        text-align: center;
        font-size: 18px;
      }
    }
    th {
      text-align: center;
    }
    td {
      padding-block: 20px;
      text-align: center;
    }

    img {
      max-height: 100px;
    }
  }
}



.courses {

  .course-title {

    margin-bottom: 20px;
    margin-top: 50px;

    & > div {
      display: flex;
      align-items: center;
    }

    h4 {
      color: $ka-green;
      text-transform: uppercase;
    }

    p,li,h1,h2,h3,h4,h5,h6,ol {
      color: white;
    }

  }

  .course-video-name {
    background-color: $ka-bg-box;
    padding-block: 10px;
    margin-top: 10px;
    margin-bottom: 50px;

    h6 {
      color: white;
      margin-bottom: 0;
      text-align: center;
    }

    p {
      color: white;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .video-button {
    background-color: $ka-bg-box;
    background-size: cover;
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.course-membership-info {
  background-color: $ka-bg-box;
  padding: 30px;
  margin-block: 30px;
  display: flex;
  gap: 30px;
  align-items: center;

  @media(max-width: 768px) {
    & {
      flex-direction: column;
    }
  }

  h3 {
    color: white;
    text-align: center;
  }
  p {
    color: white;
  }
}

.selected-files {
  display: flex;
  gap: 10px;

  > div {
    position: relative;
  }

  img {
    height: 100px;
  }

  .image-delete {
    cursor: pointer;
    position: absolute;
    top: -15px;
    right: -5px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 20px;
    padding: 5px;
    height: 25px;
    line-height: 10px;
  }
}

.product-detail-gallery {
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  img {
    height: 50px;
    width: auto;
    cursor: pointer;
  }
}

.lightbox-prev, .lightbox-next, .lightbox-close {
  z-index: 999;
}

.overflow-hidden {
  overflow: hidden;
}

/*
.slick-arrow:is(.slick-prev, .slick-next) {
  display: none !important;
}*/

.slick-slide {
  transform: translateX(158px);

  img {
    height: 300px;
  }
}


.hp-merch h2 {
  padding-right: 50px;
  line-height: 50px;
}

@media (max-width: 768px) {
  .hp-merch h2 {
    padding-inline: 10px;
    margin-bottom: 20px;
  }
}

.profile-form {
  .MuiCheckbox-root {
    svg {
      color: white;
    }
  }
}

.about-title {
  img {
    border-radius: 15px;
    width: 100%;
  }
}

.shipping-row th {
  padding-top: 30px;
}

.partners-row {
  //align-items: center;
  flex-direction: row;
  justify-content: space-between;

  > div {
    width: auto;
  }

  ul {
    list-style-type: none;
    margin-top: 20px;
    padding-left: 0;
    li {
      color: white;
      text-align:  center;
    }
  }

}

.tahaky-list img {
  width: 100%;
  margin-bottom: 20px;
}

#social-icons {
  background: transparent;
  position: fixed;
  padding-left: 20px;
  bottom: 20px;

  .social-inner {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }
}

#cryptoframe {
  width: 600px;
  height: 270px;

  @media(max-width: 768px) {
    & {
      width: 100%;
    }
  }
}

.user-detail-memberships {
  margin-top: 30px;
  td {
    padding-right: 50px;
  }
  tbody {
    td {
      font-weight: 300;
    }
  }
}

.user_active_roles {
  .role {
    text-align: center;
    padding-block: 10px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 10px;

    &.role_premium {
      background-color: $ka-green-dark;
    }
    &.role_vip {
      background-color: $ka-blue-dark;
    }
  }
}

.ka-cookies {
  margin-bottom: 0;
  background-color: black;

  & > div:first-child {
    padding: 20px;
    line-height: 26px;
  }
}

.dialog-content > div {
  margin-block: 5px;
}