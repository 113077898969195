@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../assets/fonts/Montserrat-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Montserrat', sans-serif;
  background: rgb(42,56,60);
  background: linear-gradient(180deg, rgba(42,56,60,1) 0%, rgba(37,72,76,1) 100%);

  .login {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;

    margin: -150px 0 0 -150px;

    .logo {
      text-align: center;

      img {
        width: 50px;
      }
    }

    form {
      background-color: white;
      padding: 40px;
      margin-top: 20px;
      border-radius: 15px;

      .login-component {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        input {
          border-radius: 5px;
          height: 30px;
          border: 1px solid black;
        }

        label {
          font-size: 10px;
        }
      }

      .submit {
        text-align: center;
      }

      .reset {
        margin-top: 20px;
      }
    }

    .sidebar-language {
      padding: 10px;
      position: absolute;
      right: 0;
      top: 30px;
      cursor: pointer;
      > * {
        width: 20px;
      }
    }

    .error-panel {
      text-align: center;
      margin-bottom: 10px;
      color: red;
    }

  }

}